import React from "react";
import { Link } from "gatsby";
import Button from "../common/Button";

export default function Hero({ content = {} }) {
  return (
    <div className="">
      <div className="relative  sm:pt-[126px] px-5 md:pl-[146px] 3xl:pl-2 pb-[100px] sm:pb-[142px]">
        <div
          className="absolute top-0 right-0 bottom-0 left-0 -z-10 min-h-[513px] hero fade"
          id="herobg"
        />

        <div className="max-w-[1336px] mx-auto mt-[100px] xs:mt-0 3xl:mt-[100px]">
          <h1 className="text-[30px] sm:text-[40px] leading-10 sm:leading-[60px] font-medium text-white max-w-[646px] w-full">
            {content?.title}
          </h1>
          <p className="text-[20px] leading-6 font-secondary font-normal text-white mt-[22px]">
            {content?.description}
          </p>
          <div className="flex items-center mt-[42px]">
            <Link to="/contact-us">
              <Button
                text={content?.ctaButton1.title}
                outline={content?.ctaButton1.variant}
                width="w-[144px]"
                tailwind="mr-[30px]"
              />
            </Link>
            <Link to="/signin">
              <Button
                text={content?.ctaButton2.title}
                outline={content?.ctaButton2.variant}
                width="w-[144px]"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
