import React from "react";

export default function InvestorItem({ icon, heading, content }) {
  return (
    <div className="w-[309px] flex items-center flex-col p-[1px] item-bg overflow-hidden">
      <div
        dangerouslySetInnerHTML={{ __html: icon }}
        className="h-[74px] w-[74px] mt-[41px] flex items-center justify-center rounded-full gradient-purple border-gradient"
      />

      <div className="mt-8">
        <p className="text-[20px] leading-[33px] text-white text-center font-normal tracking-[0.135em] px-5">
          {heading}
        </p>
      </div>
      <p className="mt-[15px] text-[15px] leading-[165.9%] text-white/70 text-center tracking-[0.135em] pl-6 pr-[23px] pb-[23px]">
        {content}
      </p>
    </div>
  );
}
