import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
// import { useWindowSize } from "../../hooks/windowSize";

export default function Staking({ screenHeight, data }) {
  // const { width } = useWindowSize();
  const [option, setOption] = useState(1);
  const [viewPoint, setViewPoint] = useState(false);
  // const [screenHeight, setScreenHeight] = useState(0);
  const [scrollDirection, setDirection] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      var el = document.getElementById("sticky");

      let temp = window.pageYOffset + el.getBoundingClientRect().top - 120;

      // let temp = width > 768 ? 1650 : width < 480 ? 2410 : 2120;
      if (screenHeight < temp) {
        setViewPoint(false);
        setDirection("first");
      } else if (screenHeight > temp && screenHeight < temp + 700) {
        setOption(1);
        setViewPoint(true);
      } else if (screenHeight > temp && screenHeight < temp + 1400) {
        setDirection("");
        setViewPoint(true);
        setOption(2);
      } else if (screenHeight > temp && screenHeight < temp + 2100) {
        setViewPoint(true);
        setOption(3);
      } else if (screenHeight > temp + 2200) {
        setViewPoint(false);
      }
      // } else {
      //   setViewPoint(false);
      // }
    }
  }, [screenHeight]);

  return (
    <div
      className={`relative mt-[70px] h-[2800px] sm:mt-[119px] container max-w-[1366px]`}
      id="sticky"
    >
      {/* {!viewPoint && (
        <h1
          className={`fadeslide text-[30px] sm:text-[36px] leading-10 sm:leading-[54px] font-medium text-white text-center px-5`}
        >
          {data?.title}
        </h1>
      )} */}
      <div
        className={`${
          viewPoint
            ? "fixed-view"
            : scrollDirection === "first"
            ? "opacity-0"
            : "in-view"
        }`}
        // ref={refDiv}
      >
        <div className="max-w-[1366px] mx-auto">
          <h1
            className={`text-[28px] sm:text-[32px] leading-10 sm:leading-[48px] font-medium text-white text-center px-5`}
          >
            {data?.title}
          </h1>
          <div
            className={`mt-8 flex flex-col md:flex-row ${
              viewPoint && scrollDirection === "first" && "fadeslide"
            }`}
          >
            <div className="w-full flex pl-5 xl:pl-[200px] pt-[50px] sm:pt-[120px] lg:pt-[192px]">
              <div>
                {data?.items?.map((item, index) => {
                  if (index === 0) {
                    return (
                      <p
                        key={index}
                        className={`text-xs transition-colors duration-500 sm:text-[18px] lg:text-2xl font-medium ${
                          option === 1 ? "text-white" : "text-[#31333A]"
                        } leading-[36px]`}
                      >
                        {item.label}
                      </p>
                    );
                  } else if (index === 1) {
                    return (
                      <p
                        key={index}
                        className={`text-xs sm:text-[18px] transition-colors duration-500 lg:text-2xl font-medium ${
                          option === 2 ? "text-white" : "text-[#31333A]"
                        } leading-[36px] sm:pt-[60px] lg:pt-[111px]`}
                      >
                        {item.label}
                      </p>
                    );
                  } else {
                    return (
                      <p
                        key={index}
                        className={`text-xs sm:text-[18px] transition-colors duration-500 lg:text-2xl font-medium ${
                          option === 3 ? "text-white" : "text-[#31333A]"
                        } leading-[36px] sm:pt-[90px] lg:pt-[159px] max-w-[380px]  w-full`}
                      >
                        {item.label}
                      </p>
                    );
                  }
                })}
              </div>
            </div>
            <div className="w-full">
              {/* {data?.items?.map((item, index) => {
                if (index === 0 && option === 1) {
                  return (
                    <GatsbyImage
                      image={item?.thumbnail?.asset?.gatsbyImageData}
                      alt="staking-img"
                    />
                  );
                } else if (index === 1 && option === 2) {
                  return (
                    <div className="flex h-full pt-16 items-center justify-center">
                      <div className="relative h-[536px]">
                        <GatsbyImage
                          image={item?.thumbnail?.asset?.gatsbyImageData}
                          alt="staking-img"
                        />
                      </div>
                    </div>
                  );
                } else if (index === 2 && option === 3) {
                  return (
                    <div className="flex h-full pt-16 items-center justify-center">
                      <div className="relative h-[468px]">
                        <GatsbyImage
                          image={item?.thumbnail?.asset?.gatsbyImageData}
                          alt="staking-img"
                        />
                      </div>
                    </div>
                  );
                }
              })} */}
              {option === 1 && (
                <StaticImage
                  src="../../images/blockChain.webp"
                  alt="stake"
                  className="fadeslide"
                  height={822}
                  placeholder="blurred"
                />
              )}
              {option === 2 && (
                <div className="flex h-full pt-16 px-5 md:px-0 items-center justify-center">
                  <StaticImage
                    src="../../images/realTime.webp"
                    alt="stake"
                    className="fadeslide"
                    height={536}
                    placeholder="blurred"
                  />
                </div>
              )}
              {option === 3 && (
                <div className="flex h-full pt-16 px-5 md:px-0 items-center justify-center">
                  <StaticImage
                    src="../../images/automated.webp"
                    alt="stake"
                    className="fadeslide"
                    height={468}
                    placeholder="blurred"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
