import React from "react";
import InvestorItem from "./InvestorItem";

export default function Investors({ data }) {
  return (
    <div className="container max-w-[1366px]">
      <div className="flex justify-center">
        <h1 className="text-[28px] sm:text-[32px] leading-10 sm:leading-[48px] font-medium text-white text-center px-5 lg:px-0 max-w-[795px] w-full">
          {data?.title}
        </h1>
      </div>
      <div className="mt-[112px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-0 lg:gap-y-8 place-items-center lg:place-items-stretch px-5 xl:pl-[146px] xl:pr-[147px]">
        {data?.items?.map((item, index) => (
          <InvestorItem
            key={index}
            icon={item.icon}
            heading={item?.title?.map((inner, index) => (
              <span
                key={index}
                className={`${
                  inner.type === "gradient" && "txt-gradient-purple font-bold"
                } `}
              >
                {inner.title}{" "}
              </span>
            ))}
            content={item.content}
          />
        ))}
      </div>
    </div>
  );
}
