import React, { memo } from "react";
import PricingImg1 from '../../assets/icon-server.svg';
import PricingImg2 from '../../assets/icon-server-crane.svg';

const Item = ({ icon, title, content, circleBG, borderColor, circleClass }) => {
  return (
    <div className="w-[310px]">
      <div className="flex items-center justify-center">
        <div
          dangerouslySetInnerHTML={{ __html: icon }}
          style={{
            borderColor: borderColor.hex,
            borderWidth: 1,
            backgroundColor: `rgba(${borderColor.rgb.r},${borderColor.rgb.b},${borderColor.rgb.b}, 0.2 )`,
          }}
          className={`h-[74px] w-[74px] rounded-full flex items-center justify-center ${borderColor} ${circleBG} ${circleClass}`}
        ></div>
      </div>
      <div className="mt-[42px] h-[330px] radial-gradient rounded-[30px] px-6 pt-[22px] pb-[60px]">
        <p className="text-[20px] leading-[146.4%] text-white font-medium text-center">
          {title}
        </p>
        <p className="mt-[19px] text-[15px] leading-[165.9%] tracking-[0.135em] text-white/80 font-light text-center">
          {content}
        </p>
      </div>
    </div>
  );
};

function BottomSection({ data }) {
  return (
    <>
      <div className="mt-[70px] sm:mt-[122px] mb-10">
        <div className="flex items-center flex-col">
          <div className="flex justify-center">
            <h1 className="text-[28px] sm:text-[32px] leading-10 sm:leading-[48px] font-medium text-white text-center px-5 max-w-[1077px] w-full">
              {data?.title}
            </h1>
          </div>
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 px-5 lg:px-0 gap-x-[30px] lg:gap-x-[72px] gap-y-[35px]">
            {data?.items?.map((item, index) => (
              <Item
                key={index}
                circleBG={""}
                borderColor={item.iconBg}
                title={item?.title}
                content={item?.content}
                icon={item?.icon}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="pricing" className="mt-[82px] radial-gradient rounded-[30px] px-6 pt-[32px] pb-[30px]">
        <p className="text-[32px] leading-[146.4%] text-white font-medium text-center pt-[20px]">
          Pricing
        </p>
        <p className="mt-[20px] text-[17px] leading-[165.9%] tracking-[0.135em] text-white/80 font-light text-center">
          We lead the market in innovation and price efficiency
        </p>
      </div>
      <div className={`flex items-center flex-col radial-gradient `}>
        <div className="mt-[10px] mb-10">
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 px-5 lg:px-0 gap-x-[30px] lg:gap-x-[72px] gap-y-[35px]">
            <div className="w-[310px]">
              <div className="flex items-center justify-center text-white">
                <h3 className="text-[22px] font-medium">Small Fee and Rev Share</h3>
              </div>
              <div className="mt-[22px] h-[330px] radial-gradient-light rounded-[30px] px-6 pt-[22px] pb-[60px]">
                <div className="flex items-center justify-center">
                  <div className="flex mt-[24px]">
                    <img src={PricingImg1} alt="Server" />
                  </div>
                </div>
                <div className="flex items-center justify-center flex-col">
                  <div className="flex mt-[20px]">
                    <p className="text-[24px] leading-[146.4%] font-medium text-center text-yellow px-6">
                      $15.00 + 25% Reward share
                    </p>
                  </div>
                  <div className="flex w-full mt-[10px]">
                    <p className="text-[14px] leading-[165.9%] tracking-[0.135em] text-white/80 font-light text-center">
                      This is plan is a combination of flat rate and revenue share
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[310px]">
              <div className="flex items-center justify-center text-white">
                <h3 className="text-[22px] font-medium">Revenue Share</h3>
              </div>
              <div className="mt-[22px] h-[330px] radial-gradient-light rounded-[30px] px-6 pt-[22px] pb-[60px]">
                <div className="flex items-center justify-center">
                  <div className="flex mt-[24px]">
                    <img src={PricingImg2} alt="Server" />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="flex mt-[20px]">
                    <p className="text-[24px] leading-[146.4%] font-medium text-center text-yellow">
                      45% reward share
                    </p>
                  </div>
                  <div className="flex w-full mt-[10px]">
                    <p className="text-[14px] leading-[165.9%] tracking-[0.135em] text-white/80 font-light text-center">
                      This plan is a % of the POKT production, so guaranteed profit for you!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(BottomSection);
