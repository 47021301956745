import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/home/Hero";
import Investors from "../components/home/Investor";
import OurClients from "../components/common/OurClients";
import Staking from "../components/home/Staking";
import BottomSection from "../components/home/BottomSection";
import { useWindowSize } from "../hooks/windowSize";

const IndexPage = ({
  data: {
    sanityHomePage: { hero, investor, clientSec, poktStaking, thunderFeatures },
  },
}) => {
  const { width } = useWindowSize();
  const [screenHeight, setScreenHeight] = useState(0);

  const onScroll = (e) => {
    var scrollTop = e.target.documentElement.scrollTop;
    var reveals = document.querySelectorAll(".show");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;

      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }

    if (window.innerWidth > 768) {
      if (scrollTop === 0) {
        const herobg = document.getElementById("herobg");

        herobg?.classList.remove("wave");
        // herobg?.classList.add("fade");
      } else if (scrollTop > 3 && scrollTop < 523) {
        const herobg = document.getElementById("herobg");
        const centerbg = document.getElementById("centerbg");
        herobg?.classList.remove("fade");
        herobg?.classList.add("wave");
        centerbg?.classList.remove("centerbg");
        // centerbg?.classList.add("fade");
      } else if (scrollTop > 525 && scrollTop < 1700) {
        const herobg = document.getElementById("herobg");
        const centerbg = document.getElementById("centerbg");
        herobg?.classList.remove("wave");
        herobg?.classList.add("fade");
        centerbg?.classList.add("centerbg");
      } else if (scrollTop > 1700 && scrollTop < 1750) {
        const centerbg = document.getElementById("centerbg");
        centerbg?.classList.remove("centerbg");
      }
    }
    setScreenHeight(e.target.documentElement.scrollTop);
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <Layout pFooter={true}>
        <Hero content={hero} />
        <div className="relative show">
          <div className="absolute top-0 right-0 bottom-0 left-0 -z-10">
            {width === 0 ? (
              <></>
            ) : width > 480 ? (
              <StaticImage
                src="../images/center.webp"
                alt="center-bg"
                id="centerbg"
                className="fade"
                placeholder="blurred"
              />
            ) : (
              <StaticImage
                src="../images/center-mobile.webp"
                alt="center-bg"
                id="centerbg"
                className="fade"
                placeholder="blurred"
              />
            )}
          </div>
          <Investors data={investor} />
          <div className="mt-[147px] show">
            <OurClients clients={clientSec} />
          </div>
        </div>

        <Staking screenHeight={screenHeight} data={poktStaking} />
        <div className="relative -mt-[150px] xs:mt-0 show">
          <div className="absolute top-48 right-0 bottom-0 left-0 -z-10">
            <StaticImage
              src="../images/barline.webp"
              alt="bar-lines"
              placeholder="blurred"
              className="barline"
            />
          </div>
          <BottomSection data={thunderFeatures} />
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    sanityHomePage {
      hero {
        title
        description
        ctaButton1 {
          title
          type
          variant
        }
        ctaButton2 {
          title
          type
          variant
        }
      }
      investor {
        title
        items {
          content
          icon
          title {
            title
            type
          }
        }
      }
      clientSec {
        title
        clientList {
          title
          logo {
            asset {
              gatsbyImageData(
                fit: CLIP
                placeholder: BLURRED
                height: 61
                formats: WEBP
              )
            }
          }
        }
      }
      poktStaking {
        title
        items {
          label
          thumbnail {
            asset {
              gatsbyImageData(fit: MAX, placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
      thunderFeatures {
        title
        items {
          icon
          content
          title
          iconBg {
            hex
            rgb {
              a
              b
              g
              r
            }
          }
        }
      }
    }
  }
`;
